import { useEffect, useState } from 'react';

const AddScrollListener = (startingValue, benchmark) => {
  const [value, setValue] = useState(startingValue);

  useEffect(() => {
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      scrollY > benchmark ? setValue(false) : setValue(true);
      ticking = false;
      return;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll, true);

    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return value;
};
export default AddScrollListener;
