import styled from 'styled-components';

import { toRem } from 'utils/mixins';
import { brand } from 'theme/colors';

export const Wrapper = styled.div`
  top: 0;
  left: 0;
  display: block;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: ${brand.main};
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: ${toRem(300)};
  }
`;
