import React from 'react';

import { WrapperLink, WrapperButton } from './Button.styles';

const Button = (props) => {
  //PROPS
  const { children, onClick, to, disabled, white, type, light } = props;
  //PROPS

  if (to) {
    return (
      <WrapperLink to={to} white={white} light={light}>
        {children}
      </WrapperLink>
    );
  }

  return (
    <WrapperButton onClick={onClick} disabled={disabled} type={type}>
      {children}
    </WrapperButton>
  );
};

export default Button;
