import React, { useEffect, useState } from 'react';

import { Link, useStaticQuery, graphql } from 'gatsby';

import { NavLi, Wrapper, Expand, SubWrapper, Sub, SubLi } from './Nav.styles';

import { neutral } from 'theme/colors';

const NavItem = (props) => {
  const {
    data: { path, label, children },
    setSubData,
    toggleMenu,
    textTheme,
    setToggleMenu,
  } = props;

  return (
    <NavLi className="nav-column" textTheme={textTheme} toggleMenu={toggleMenu}>
      {children.length ? (
        <Expand
          textTheme={textTheme}
          toggleMenu={toggleMenu}
          onClick={() => setSubData({ show: true, data: children })}
        >
          {label} +
        </Expand>
      ) : (
        <Link
          to={path}
          onClick={() => {
            setToggleMenu(false);
          }}
        >
          {label}
        </Link>
      )}
    </NavLi>
  );
};

const Nav = (props) => {
  //PROPS
  const { textTheme, setCustomTheme, toggleMenu, setToggleMenu } = props;
  //PROPS

  //DATA
  const data = useStaticQuery(graphql`
    query Menu {
      allWpMenu {
        edges {
          node {
            name
            menuItems {
              nodes {
                path
                label
                parentId
                id
                connectedNode {
                  node {
                    ... on WpPage {
                      seo {
                        seoDescription
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    allWpMenu: { edges },
  } = data;
  //DATA

  //DEFS
  const menuItems = edges.filter((el) => el.node.name === 'Menu')[0].node
    .menuItems.nodes;

  const menu = [];

  menuItems.forEach((el) => {
    if (!el.parentId) {
      return menu.push({ ...el, children: [] });
    }

    const fatherIndex = menu.findIndex(
      (elParent) => elParent.id === el.parentId
    );

    return menu[fatherIndex].children.push({ ...el, children: [] });
  });
  //DEFS

  //STATES
  const [subData, setSubData] = useState({ data: [], show: false });
  //STATES

  //EFFECTS
  useEffect(() => {
    if (subData.show) {
      setCustomTheme(true);
    } else {
      setCustomTheme(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subData]);

  useEffect(() => {
    setSubData({ ...subData, show: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleMenu]);
  //EFFECTS

  return (
    <>
      <Wrapper className="navContainer" toggleMenu={toggleMenu}>
        <ul className="menu">
          {menu &&
            menu.map((item, index) => (
              <NavItem
                setToggleMenu={setToggleMenu}
                toggleMenu={toggleMenu}
                data={item}
                key={index}
                textTheme={textTheme}
                setSubData={setSubData}
              />
            ))}
        </ul>
      </Wrapper>
      <SubWrapper
        onClick={() => setSubData({ ...subData, show: false })}
        toggleMenu={toggleMenu}
        className={subData.show ? 'active' : ''}
      >
        <button className="back__button">
          <svg
            viewBox="0 0 32 32"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M31.106 15H3.278l8.325-8.293a.999.999 0 1 0-1.414-1.414l-9.9 9.899a1.01 1.01 0 0 0 0 1.414l9.9 9.9a.999.999 0 1 0 1.414-1.414L3.278 17h27.828a1 1 0 0 0 0-2z"
              fill={neutral.neu10}
              fillRule="evenodd"
            />
          </svg>
        </button>
        <Sub className={subData.show ? 'active' : ''}>
          {subData.data.map((subItem, index) => {
            const {
              label,
              path,
              connectedNode: {
                node: {
                  seo: { seoDescription },
                },
              },
            } = subItem;

            return (
              <SubLi key={index}>
                <Link to={path} onClick={() => setToggleMenu(false)}>
                  <h4>{label}</h4>
                  <p>{seoDescription}</p>
                </Link>
              </SubLi>
            );
          })}
        </Sub>
      </SubWrapper>
    </>
  );
};

export default Nav;
