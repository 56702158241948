import React from 'react';

import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Wrapper, Body, Copy } from './Footer.styles';

const Footer = () => {
  //DATA
  const data = useStaticQuery(graphql`
    query Footer {
      wpPage(id: { eq: "cG9zdDoxMTcy" }) {
        footer {
          about {
            short
            logo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
          linksLegais {
            linkLegal {
              title
              url
            }
          }
          socialMedia {
            icon {
              altText
              mediaItemUrl
            }
            url
          }
          column {
            links {
              link {
                url
                title
              }
            }
            title
          }
        }
      }
    }
  `);

  const {
    wpPage: {
      footer: {
        column,
        linksLegais,
        about: { logo },
      },
    },
  } = data;
  //DATA

  return (
    <Wrapper>
      <Body>
        <div className="footer__upper-logo">
          <GatsbyImage
            image={logo?.localFile?.childImageSharp?.gatsbyImageData}
            alt={logo?.altText}
          />
        </div>
        {column.map((col, index) => {
          return (
            <ul key={index} className="footer__upper-col">
              <li className="footer__upper-title">{col.title}</li>
              {col.links.map(({ link }, lIndex) => {
                return (
                  <li key={lIndex} className="footer__upper-link">
                    <Link to={link.url}>{link.title}</Link>
                  </li>
                );
              })}
            </ul>
          );
        })}
      </Body>
      <Copy>
        <p className="footer__copy-text">© 2022 Nutri2coach</p>
        <ul className="footer__copy-legal">
          {linksLegais.map(({ linkLegal }, index) => {
            return (
              <li key={index}>
                <Link to={linkLegal.url}>{linkLegal.title}</Link>
              </li>
            );
          })}
        </ul>
      </Copy>
    </Wrapper>
  );
};

export default Footer;
