import { useState, useEffect } from 'react';

import { debounce } from 'utils/mixins';

const isBrowser = typeof window !== `undefined`;

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(
    isBrowser ? { width: window?.innerWidth, height: window?.innerHeight } : {}
  );

  useEffect(() => {
    if (!isBrowser) {
      return false;
    }

    const debouncedHandleResize = debounce(function handleResize() {
      setWindowSize({ width: window?.innerWidth, height: window?.innerHeight });
    }, 100);

    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  return windowSize;
};
export default useWindowSize;
