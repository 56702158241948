import styled from 'styled-components';

import { Link } from 'gatsby';

import { toRem, breakpoints } from 'utils/mixins';
import { neutral, brand } from 'theme/colors';
import { container, space, fontSize } from 'theme/sizes';

export const WrapperButton = styled.button`
  display: flex;
  align-items: center;
  font-size: ${fontSize.xxs};
  line-height: ${fontSize.xxs};
  text-transform: uppercase;
  color: ${(props) => (props.white ? brand.main : neutral.neu01)};
  background-color: ${(props) => (props.white ? neutral.neu01 : brand.main)};
  padding: ${space.xs} ${space.s};
  border-radius: ${toRem(9999)};
  transition: 250ms;
  border: ${toRem(1)} solid
    ${(props) => (props.white ? neutral.neu01 : brand.main)};

  &:disabled {
    border: ${toRem(1)} solid ${neutral.neu04};
    background-color: ${neutral.neu04};
    color: ${neutral.neu10};

    &:hover {
      color: ${neutral.neu10};
      background-color: ${neutral.neu04};
    }
  }

  &:hover {
    background-color: ${(props) => (props.white ? brand.main : neutral.neu01)};
    color: ${(props) => (props.white ? neutral.neu01 : brand.main)};
  }

  @media ${breakpoints.mobileonly} {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const WrapperLink = styled(Link)`
  text-decoration: none;
  display: block;
  font-size: ${fontSize.xxs};
  line-height: ${fontSize.xxs};
  text-transform: uppercase;
  color: ${(props) =>
    props.white ? brand.main : props.light ? brand.main : neutral.neu01};
  background-color: ${(props) =>
    props.white ? neutral.neu01 : props.light ? brand.fourth : brand.main};
  padding: ${space.xs} ${space.s};
  border-radius: ${toRem(9999)};
  transition: 250ms;
  border: ${toRem(1)} solid
    ${(props) => (props.light ? brand.fourth : brand.main)};

  &:hover {
    background-color: ${(props) =>
      props.white ? brand.main : props.light ? brand.fourth : neutral.neu01};
    color: ${(props) => (props.white ? neutral.neu01 : brand.main)};
    border: ${toRem(1)} solid ${(props) => brand.fourth && brand.main};
  }
`;

export const WrapperButtonSecondary = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 ${toRem(4)};
  font-size: ${fontSize.xxs};
  text-transform: uppercase;
  color: ${brand.main};
  padding: ${space.xxs};
  border-radius: ${toRem(9999)};
  border: ${toRem(1)} solid ${brand.main};
  transition: 250ms;

  @media ${breakpoints.laptop} {
    padding: ${space.xs};
    margin: 0 ${space.xxs};
  }

  &:hover {
    border: ${toRem(1)} solid ${neutral.neu01};
  }
`;

export const WrapperLinkSecondary = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 8px;
  font-size: ${fontSize.xxs};
  text-transform: uppercase;
  color: ${neutral.neu01};
  background-color: ${brand.main};
  padding: ${space.xxs};
  border-radius: ${toRem(9999)};
  border: ${toRem(1)}solid ${brand.main};
  transition: 250ms;

  &:hover {
    border: ${toRem(1)} solid ${neutral.neu01};
  }
`;

export const WrapperButtonGenericBtn = styled.button`
  position: relative;
  display: block;
  font-size: ${toRem(12)};
  line-height: ${toRem(12)};
  text-transform: uppercase;
  color: ${neutral.neu10};
  background-color: ${neutral.neu03};
  padding: ${toRem(12)} ${toRem(16)} ${toRem(12)} ${toRem(32)};
  border-radius: ${toRem(6)};
  transition: 250ms;
  border: 1px solid ${neutral.neu05};

  &.active {
    border: 1px solid ${(props) => props.color};
  }

  &::before {
    content: '';
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-4px);
    height: ${toRem(8)};
    width: ${toRem(8)};
    border-radius: ${toRem(8)};
    background-color: ${(props) => props.color};
  }
`;

export const WrapperButtonGeneric = styled(Link)`
  text-decoration: none;
  position: relative;
  display: block;
  font-size: ${toRem(12)};
  line-height: ${toRem(12)};
  text-transform: uppercase;
  color: ${neutral.neu10};
  background-color: ${neutral.neu03};
  padding: ${toRem(12)} ${toRem(16)} ${toRem(12)} ${toRem(32)};
  border-radius: ${toRem(6)};
  transition: 250ms;
  border: 1px solid ${neutral.neu05};

  &.active {
    border: 1px solid ${(props) => props.color};
  }

  &::before {
    content: '';
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-4px);
    height: ${toRem(8)};
    width: ${toRem(8)};
    border-radius: ${toRem(8)};
    background-color: ${(props) => props.color};
  }
`;

export const WrapperButtonLinkBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 ${toRem(4)};
  font-size: ${fontSize.xxs};
  text-transform: uppercase;
  color: ${neutral.neu01};
  padding: ${space.xxs};
  border-radius: ${toRem(9999)};
  border: ${toRem(1)} solid ${brand.main};
  transition: 250ms;

  @media ${breakpoints.laptop} {
    padding: ${space.xs};
    margin: 0 ${space.xxs};
  }

  &:hover {
    border: ${toRem(1)} solid ${neutral.neu01};
  }
`;

export const WrapperButtonLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 8px;
  font-size: ${fontSize.xxs};
  text-transform: uppercase;
  color: ${neutral.neu01};
  background-color: ${brand.main};
  padding: ${space.xxs};
  border-radius: ${toRem(9999)};
  border: ${toRem(1)}solid ${brand.main};
  transition: 250ms;

  &:hover {
    border: ${toRem(1)} solid ${neutral.neu01};
  }
`;
