import { css } from 'styled-components';

import { toRem, breakpoints } from 'utils/mixins';
import { fontSize } from 'theme/sizes';

export const h1 = css`
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 400;
  font-size: ${toRem(80)};
  margin: 0;
  margin-bottom: ${toRem(20)};
  line-height: normal;
  @media (orientation: portrait) {
    font-size: ${toRem(45)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(40)};
  }
`;

export const h2 = css`
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 400;
  font-size: ${toRem(36)};
  margin-bottom: ${toRem(12)};
  @media ${breakpoints.bigDesktop} {
    font-size: ${toRem(36)};
  }
  @media (orientation: portrait) {
    font-size: ${toRem(44)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(36)};
  }
`;

export const h3 = css`
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: ${toRem(18)};
  line-height: normal;
  @media (orientation: portrait) {
    font-size: ${toRem(18)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(18)};
  }
`;

export const h4 = css`
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 400;
  font-size: ${fontSize.xs};
  line-height: 1;
  text-transform: uppercase;
  line-height: normal;
`;

export const p = css`
  font-family: 'Lato', sans-serif;
  font-size: ${fontSize.m};
  line-height: 1.25;
  font-weight: 300;
`;
