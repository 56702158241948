import styled from 'styled-components';

import { p } from 'theme/typography';
import { neutral } from 'theme/colors';
import { space } from 'theme/sizes';
import { breakpoints } from 'utils/mixins';

export const CookieContainer = styled.div`
  width: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  bottom: 0;
  padding: ${space.xs};

  .cookie__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    color: ${neutral.neu01};

    p {
      max-width: 100%;
      text-align: left;
    }
  }
  @media ${breakpoints.laptop} {
    .cookie__wrapper {
      flex-wrap: nowrap;
      justify-content: space-between;

      p {
        max-width: calc(100% - 300px);
      }
    }
  }
`;
