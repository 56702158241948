import styled from 'styled-components';

import { neutral } from 'theme/colors';
import { container, space } from 'theme/sizes';

import { toRem, breakpoints } from 'utils/mixins';

export const Wrapper = styled.footer`
  padding: 0 ${space.xs} ${space.xs};
  background: ${neutral.neu02};
  overflow-x: hidden;

  @media ${breakpoints.laptop} {
    padding: ${space.l} ${space.xs};
  }
`;

export const Body = styled.div`
  max-width: ${container.xsmall};
  margin: 0 auto;
  padding: ${space.m} 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  .footer__upper-logo {
    display: none;
  }

  .footer__upper-col {
    width: 100%;
    padding: ${space.s} 0;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${toRem(2)};
      background-color: ${neutral.neu03};
    }
  }

  .footer__upper-title {
    text-transform: uppercase;
    font-weight: 600;
    color: ${neutral.neu09};
    margin-bottom: ${space.xs};
  }

  .footer__upper-link {
    margin-bottom: ${space.xxs};

    a {
      text-decoration: none;
    }
  }
  @media ${breakpoints.mobileonly} {
    text-align: center;
  }

  @media ${breakpoints.phablet} {
    text-align: left;
    .footer__upper-col {
      width: 50%;

      &::after {
        content: none;
      }
    }
  }

  @media ${breakpoints.laptop} {
    margin-bottom: ${space.xl};

    .footer__upper-logo {
      display: block;
      width: ${toRem(40)};
    }
    .footer__upper-col {
      width: unset;
      padding: 0;
    }
  }

  @media ${breakpoints.bigDesktop} {
    max-width: ${container.default};
  }
`;

export const Copy = styled.div`
  max-width: ${container.xsmall};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;

  .footer__copy-text {
    color: ${neutral.neu09};
    margin-top: ${space.s};
  }

  .footer__copy-legal {
    display: flex;
    align-items: flex-start;
    padding: ${space.s} 0;
    flex-direction: column;

    li {
        margin: 0;
      a {
        color: ${neutral.neu08};
        text-decoration: underline;
      }

      &:first-of-type {
        margin-bottom: ${space.xxs};
      }
    }
  }

  @media ${breakpoints.phablet} {
    .footer__copy-legal {
      padding: unset;
      flex-direction: row;

      li {
        &:first-of-type {
          margin-right: ${space.xs};
        }
      }
    }
  }

  @media ${breakpoints.tabletSpec} {
    flex-direction: row;

    .footer__copy-text {
      margin: 0;
    }
  }

  @media ${breakpoints.bigDesktop} {
    max-width: ${container.default};
  }
`;
