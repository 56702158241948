import React from 'react';

import { WrapperButtonGeneric, WrapperButtonGenericBtn } from './Button.styles';

const ButtonGeneric = (props) => {
  //PROPS
  const { children, onClick, to, color } = props;
  //PROPS

  if (to) {
    return (
      <WrapperButtonGeneric to={to} color={color}>
        {children}
      </WrapperButtonGeneric>
    );
  }

  return (
    <WrapperButtonGenericBtn onClick={onClick} color={color}>
      {children}
    </WrapperButtonGenericBtn>
  );
};

export default ButtonGeneric;
