exports.components = {
  "component---src-templates-custom-404-template-js": () => import("./../../../src/templates/custom/404Template.js" /* webpackChunkName: "component---src-templates-custom-404-template-js" */),
  "component---src-templates-custom-blog-template-js": () => import("./../../../src/templates/custom/BlogTemplate.js" /* webpackChunkName: "component---src-templates-custom-blog-template-js" */),
  "component---src-templates-custom-consult-template-js": () => import("./../../../src/templates/custom/ConsultTemplate.js" /* webpackChunkName: "component---src-templates-custom-consult-template-js" */),
  "component---src-templates-custom-contacts-template-js": () => import("./../../../src/templates/custom/ContactsTemplate.js" /* webpackChunkName: "component---src-templates-custom-contacts-template-js" */),
  "component---src-templates-custom-empresa-template-js": () => import("./../../../src/templates/custom/EmpresaTemplate.js" /* webpackChunkName: "component---src-templates-custom-empresa-template-js" */),
  "component---src-templates-custom-equipa-template-js": () => import("./../../../src/templates/custom/EquipaTemplate.js" /* webpackChunkName: "component---src-templates-custom-equipa-template-js" */),
  "component---src-templates-custom-homepage-template-js": () => import("./../../../src/templates/custom/HomepageTemplate.js" /* webpackChunkName: "component---src-templates-custom-homepage-template-js" */),
  "component---src-templates-custom-parceiros-template-js": () => import("./../../../src/templates/custom/ParceirosTemplate.js" /* webpackChunkName: "component---src-templates-custom-parceiros-template-js" */),
  "component---src-templates-custom-privacy-template-js": () => import("./../../../src/templates/custom/PrivacyTemplate.js" /* webpackChunkName: "component---src-templates-custom-privacy-template-js" */),
  "component---src-templates-custom-recrutamento-template-js": () => import("./../../../src/templates/custom/RecrutamentoTemplate.js" /* webpackChunkName: "component---src-templates-custom-recrutamento-template-js" */),
  "component---src-templates-custom-servicos-corporativos-template-js": () => import("./../../../src/templates/custom/ServiçosCorporativosTemplate.js" /* webpackChunkName: "component---src-templates-custom-servicos-corporativos-template-js" */),
  "component---src-templates-custom-servicos-pessoais-template-js": () => import("./../../../src/templates/custom/ServiçosPessoaisTemplate.js" /* webpackChunkName: "component---src-templates-custom-servicos-pessoais-template-js" */),
  "component---src-templates-custom-servicos-template-js": () => import("./../../../src/templates/custom/ServiçosTemplate.js" /* webpackChunkName: "component---src-templates-custom-servicos-template-js" */),
  "component---src-templates-custom-terms-template-js": () => import("./../../../src/templates/custom/TermsTemplate.js" /* webpackChunkName: "component---src-templates-custom-terms-template-js" */),
  "component---src-templates-general-post-js": () => import("./../../../src/templates/general/Post.js" /* webpackChunkName: "component---src-templates-general-post-js" */)
}

