import React, { useState, useEffect } from 'react';

import { Link } from 'gatsby';

import Nav from './nav';
import { Button } from 'components/button';
import { Container, Wrapper, Logo, Toggle } from './Header.styles';

import AddScrollListener from 'utils/addScrollListener';
import useWindowSize from 'utils/useWindowSize';

const Header = (props) => {
  const [customTheme, setCustomTheme] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  const menuTheme = AddScrollListener(true, 150);
  const { width } = useWindowSize();

  const isHome = !props.location.replaceAll('/', '');

  const headerTheme = {
    backgroundTheme: customTheme ? !customTheme : menuTheme,
    textTheme: customTheme ? !customTheme : isHome ? menuTheme : false,
  };

  useEffect(() => {
    setToggleMenu(false);
  }, [width]);

  return (
    <Container {...headerTheme}>
      <Wrapper id="header">
        <Logo toggleMenu={toggleMenu} {...headerTheme}>
          <Link to="/">
            <span>Nutri2coach</span>
          </Link>
        </Logo>
        <Nav
          {...headerTheme}
          setCustomTheme={setCustomTheme}
          setToggleMenu={setToggleMenu}
          toggleMenu={toggleMenu}
        />
        {width < 1024 && (
          <Toggle
            onClick={() => setToggleMenu(!toggleMenu)}
            toggleMenu={toggleMenu}
            {...headerTheme}
          >
            <span className="span span--top"></span>
            <span className="span span--center"></span>
            <span className="span span--bottom"></span>
          </Toggle>
        )}
        <div className="header__cta">
          <Button to="/ser-parceiro">Junte-se a nós</Button>
        </div>
      </Wrapper>
    </Container>
  );
};

export default Header;
