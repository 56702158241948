import React, { useEffect, useState } from 'react';

import { CookiesProvider } from 'react-cookie';
import { AnimatePresence } from 'framer-motion';

import { GlobalStyles } from 'theme/global';

import CookieBar from 'components/cookieBar';
import CreateHead from 'components/createHeader';
import Loader from 'components/loader';
import Header from 'components/header';
import Footer from 'components/footer';

const Layout = (props) => {
  //STATE
  const [isMenuOpen, setMenuOpen] = useState(false);
  //STATE

  //DEFS
  const seo = props.pageContext?.seo;
  //DEFS

  //EFFECT
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);
  //EFFECT

  //CHILDREN MODIFIER
  const childrenWithProps = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {});
  });
  //CHILDREN MODIFIER

  return (
    <CookiesProvider>
      <GlobalStyles />
      <CreateHead
        title={seo?.seoTitle}
        description={seo?.seoDescription}
        image={seo?.seoImage}
      />
      <Loader />
      <Header
        isMenuOpen={isMenuOpen}
        setMenuOpen={setMenuOpen}
        location={props.location.pathname}
      />
      <AnimatePresence initial={false}>{childrenWithProps}</AnimatePresence>
      <Footer />
      <CookieBar />
    </CookiesProvider>
  );
};

export default Layout;
