import styled from 'styled-components';

import { neutral, brand } from 'theme/colors';
import { toRem, breakpoints, gridSize } from 'utils/mixins';
import { space, fontSize, zIndex } from 'theme/sizes';

export const Wrapper = styled.nav`
  overflow: visible;
  background-color: ${neutral.neu01};
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props) =>
    props.toggleMenu ? 'translateY(0)' : 'translateY(-100%)'};
  ${zIndex.above}

  @media ${breakpoints.laptop} {
    background-color: transparent;
    height: auto;
    width: auto;
    position: relative;
    left: unset;
    top: unset;
    display: block;
    align-items: unset;
    justify-content: unset;
    transform: translateY(0);
    width: ${gridSize(6)};

    .menu {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
`;

export const NavLi = styled.li`
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: ${space.s};

  a {
    color: ${(props) =>
      props.toggleMenu
        ? neutral.neu10
        : props.textTheme
        ? neutral.neu10
        : neutral.neu10};
    text-transform: uppercase;
    line-height: normal;
    font-weight: 300;
    font-size: ${fontSize.l};
    padding: ${space.xxs};
    text-align: center;
    min-width: ${space.xl};
    text-decoration: none;
  }

  @media ${breakpoints.laptop} {
    min-width: ${space.m};
    margin-bottom: 0;
    justify-content: flex-start;

    &:first-of-type {
      margin-left: 0;
    }

    a,
    button {
      font-size: ${fontSize.xxs};
      line-height: 1;
    }

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: ${toRem(4)};
      display: block;
      top: 0;
      right: 0;
      transition: 0.4s ease;
      background-color: ${brand.main};
    }

    &:hover {
      &::before {
        width: 100%;
        left: 0;
      }
    }
  }
`;

export const Expand = styled.button`
  color: ${(props) =>
    props.toggleMenu
      ? neutral.neu10
      : props.textTheme
      ? neutral.neu10
      : neutral.neu10};
  text-transform: uppercase;
  line-height: normal;
  position: relative;
  font-weight: 300;
  font-size: ${fontSize.l};
  padding: ${space.xxs};
  text-align: center;
  min-width: ${space.l};

  @media ${breakpoints.laptop} {
    font-size: ${fontSize.xs};
    line-height: 1;
  }
`;

export const SubWrapper = styled.div`
  position: fixed;
  top: ${space.header};
  left: 0;
  height: calc(100% - 80px);
  width: 100%;
  overflow: hidden;
  transform: translateX(-100%);
  transition: opacity 0.6s cubic-bezier(0.33, 1, 0.68, 1),
    transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);
  overflow-y: auto;
  ${zIndex.top}

  &.active {
    transform: translateX(0);
  }

  .back__button {
    padding: ${space.xs} ${space.s};

    svg {
      width: ${space.xs};
    }
  }

  @media ${breakpoints.laptop} {
    opacity: 0;
    transform: unset;
    background-color: ${`${neutral.neu10}B3`};
    pointer-events: none;

    &.active {
      pointer-events: all;
      opacity: 1;
    }
  }
`;

export const Sub = styled.ul`
  top: 0;
  left: 0;
  width: 100%;
  min-height: calc(100% - 52px);
  background-color: ${neutral.neu01};
  display: flex;
  flex-direction: column;
  list-style: none;
  transition: 0.6s cubic-bezier(0.33, 1, 0.68, 1);
  transform: translateY(0);

  @media ${breakpoints.laptop} {
    position: absolute;
    flex-direction: row;
    height: auto;
    min-height: unset;

    transform: translateY(-100%);
    &.active {
      transform: translateY(0);
    }
  }
`;

export const SubLi = styled.li`
  //flex: 1;
  padding: ${space.s};
  width: 100%;
  background-color: ${neutral.neu03};
  margin: 0;

  a {
    display: block;
    height: 100%;
    text-decoration: none;
  }

  @media ${breakpoints.laptop} {
    &:not(:last-child) {
      border-right: 3px solid ${neutral.neu02};
    }
  }
`;
