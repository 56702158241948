import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { breakpoints } from 'utils/mixins';
import { brand, neutral } from './colors';
import { container, fontSize, space } from './sizes';
import { p, h1, h2, h3, h4 } from './typography';

export const GlobalStyles = createGlobalStyle`
  ${normalize}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  b {
    font-weight: bold;
  }

  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    background: ${neutral.neu01};
  }

  body {
    opacity: 0;
    background-attachment: fixed;
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    line-height: 1.4;
    font-size:  ${fontSize.s};
    -webkit-font-smoothing: antialiased;
    color: ${neutral.neu10};
    -moz-osx-font-smoothing: grayscale;
    cursor: default;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 400;
    color: ${neutral.neu10};
    transition: 250ms;
  }


  h1 {
    ${h1}
  }

  h2 {
    ${h2}
  }

  h3 {
    ${h3}
  }

  h4 {
    ${h4}
  }

  p,
  label,
  li {
    ${p}
    margin-bottom: 1em;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  img {
    max-width: 100%;
  }

  a {
    text-decoration: underline;
    color: inherit;
    position: relative;
    transition: 250ms;
  }

  button {
    appearance: none;
    display: inline-block;
    border-radius: 0;
    background: none;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
  }

  address {
    font-style: normal;
  }

  .main {
    padding-top: ${space.header};
    min-height: calc(100vh - 80px);

    &--top {
      padding-top: 0;
    }
  }

  .wrapper {
    padding-top: ${space.header};
    min-height: calc(100vh - 80px);
    position: relative;
  }

  .wrapper--breaker{
    padding:${space.header} 0;
    position: relative;
    
    #visual {
      z-index:-1;
      position: absolute;
      bottom: 33%;
      left: 0;
      width: 100%;
      
      path {
      width: 100%;
      }
    }

   &::after {
      display: none;
      z-index:-1;
      content: "";
      position: absolute;
      height: 33%;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: ${brand.fourth};
    }
        
    @media ${breakpoints.fromMobile} {
      &::after {
        display: block;
      }   
    }
   
      @media ${breakpoints.laptop} {
      &::after {
        display: block;
      }   
    }
  }
  .wrapper--blog{
    background-color: ${brand.fourth};
    margin-top: 0;
    margin-bottom: 0;
  }
  .container {
    width: 100%;
    margin: ${space.l} auto;

    &--default {
      max-width: ${container.default};
    }

    &--xsmall {
      max-width: ${container.xsmall};
    }

    &--404{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

`;
