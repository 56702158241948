export const toRem = (size) => `${size / 10}rem`;

export const gridSize = (col, extra) => {
  return extra
    ? `calc((100% - 168px) / 8 * ${col} + ${(col - 1) * 24}px + ${extra}px)`
    : `calc((100% - 168px) / 8 * ${col} + ${(col - 1) * 24}px)`;
};

export const breakpoints = {
  // 1920px
  largeDesktop: '(min-width: 120em)',

  // 1440px
  bigDesktop: '(min-width: 90em)',

  // 1120px
  desktop: '(min-width: 70em)',

  // 1024px
  laptop: '(min-width: 64em)',

  // 850px
  tabletSpec: '(min-width: 53.125em)',

  // 768px
  tablet: '(min-width: 48em)',

  // 600px
  phablet: '(min-width: 37.5em)',

  // 360px
  fromBigMobile: '(min-width: 22.5rem)',

  // 300px
  fromMobile: '(min-width: 18.75em)',

  // 400px
  smallmobileonly: '(max-width: 25em)',

  // 768px
  belowTablet: '(max-width: 48em)',

  // 600px
  mobileonly: '(max-width: 37.5em)',
};

export const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaaeeeeiiiioooouuuunc------';

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

export const isOdd = (num) => {
  return num % 2;
};

export const removeItemArr = ({ arr, item }) => {
  const newData = [...arr];
  for (var i = 0; i < newData.length; i++) {
    if (newData[i] === item) {
      newData.splice(i, 1);
    }
  }
  return newData;
};

export const addItemArr = ({ arr, item }) => {
  const newData = [...arr];
  newData.push(item);
  return newData;
};

export function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
