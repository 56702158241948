export const brand = {
  main: '#DFA34B',
  secondary: '#ECD8B6',
  third: '#B2823C',
  fourth: '#FCF1E6',
};

export const neutral = {
  neu01: '#ffffff',
  neu011: '#fefefe',
  neu02: '#F7F7F7',
  neu03: '#EFF3F8',
  neu04: '#E8ECF1',
  neu05: '#C7CAD0',
  neu06: '#A9ADB2',
  neu07: '#7F8387',
  neu08: '#6B6E73',
  neu09: '#4B4E53',
  neu10: '#292C30',
};

export const white = {
  default: '#ffffff',
  base: '#f5f5f5',
  darker: '#f7f7f8',
};

export const secondary = {
  orange: '#FF5403',
  green: '#8EBDB0',
  blue: '#418aca',
  yellow: '#FFF323',
  red: '#F90716',
};

export const danger = {
  default: '#F04438',
  darker: '#C0362C',
};

export const success = {
  default: '#4caf50',
};
