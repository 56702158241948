import React from 'react';

import { WrapperLinkSecondary, WrapperButtonSecondary } from './Button.styles';

const ButtonSecondary = (props) => {
  //PROPS
  const { children, onClick, to } = props;
  //PROPS

  if (to) {
    return <WrapperLinkSecondary to={to}>{children}</WrapperLinkSecondary>;
  }

  return (
    <WrapperButtonSecondary onClick={onClick}>
      {children}
    </WrapperButtonSecondary>
  );
};

export default ButtonSecondary;
