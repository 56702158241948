import React from 'react';

import { useCookies } from 'react-cookie';
import Link from 'gatsby-link';

import { Button } from 'components/button';

import { CookieContainer } from './CookieBar.styles';

const CookieBar = () => {
  //COOKIES
  const [cookies, setCookie] = useCookies(['hasCookiesDEV']);
  //COOKIES

  //FUNCS
  const handleCookie = () => {
    setCookie('hasCookiesDEV', 'true', { path: '/' });
  };
  //FUNCS

  if (cookies.hasCookiesDEV !== 'true') {
    return (
      <CookieContainer>
        <div className="cookie__wrapper">
          <p>
            As cookies servem para ajudar o website a funcionar corretamente e
            melhorar a sua experiência de navegação. Clique em "Aceitar Cookies"
            para avançar. Para mais informações clique{' '}
            <Link to="/politica-cookies">aqui</Link>.
          </p>
          <Button onClick={() => handleCookie()}>Aceitar Cookies</Button>
        </div>
      </CookieContainer>
    );
  } else {
    return null;
  }
};

export default CookieBar;
