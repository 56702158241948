import styled from 'styled-components';

import { neutral, brand } from 'theme/colors';
import { toRem, breakpoints, gridSize } from 'utils/mixins';
import { container, space, fontSize } from 'theme/sizes';

export const Container = styled.header`
  position: fixed;
  width: 100%;
  padding: 0 ${space.s};
  left: 0;
  top: 0;
  z-index: 20;
  transition: 0.6s cubic-bezier(0.33, 1, 0.68, 1);
  height: ${space.header};
  background-color: ${(props) =>
    props.backgroundTheme ? 'transparent' : neutral.neu02};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  z-index: 20;
  transition: 150ms;

  .header__cta {
    min-width: ${toRem(180)};
    width: ${gridSize(1)};
    display: none;
    align-items: center;
    justify-content: center;
  }

  @media ${breakpoints.laptop} {
    justify-content: space-between;
    max-width: ${container.default};
    width: calc(100% - 140px);

    .header__cta {
      display: flex;
    }
  }
`;

export const Logo = styled.div`
  position: relative;
  z-index: 21;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  span {
    font-family: 'Hind Vadodara', sans-serif;
    text-transform: uppercase;
    color: ${(props) =>
      props.toggleMenu
        ? brand.main
        : props.textTheme
        ? brand.main
        : brand.main};
    font-size: ${fontSize.s};
    letter-spacing: ${toRem(2)};
  }

  img {
    height: ${space.s};
  }

  @media ${breakpoints.laptop} {
    width: ${gridSize(1)};
  }
`;

export const Toggle = styled.button`
  position: relative;
  cursor: pointer;
  z-index: 21;
  width: ${toRem(20)};
  height: ${toRem(16)};
  align-self: center;
  display: block;

  .span {
    top: ${toRem(7)};
    background-color: ${(props) =>
      props.toggleMenu
        ? brand.main
        : props.textTheme
        ? brand.main
        : brand.main};
    width: ${toRem(20)};
    height: ${toRem(2)};
    display: block;
    position: absolute;

    &--top {
      position: absolute;
      top: 0;
    }

    &--bottom {
      position: absolute;
      top: ${toRem(14)};
    }
  }

  @media ${breakpoints.laptop} {
    display: none;
  }
`;
